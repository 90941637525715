<template>
	<div :class="[active ? 'green' : 'red']"></div>
</template>

<script>
export default {
	name: "Ball",
	props: {
		active: Boolean,
	},
};
</script>

<style lang="scss" scoped>
div {
	background-color: red;
	border-radius: 50%;
	width: 1.3rem;
	height: 1.3rem;
}

.green {
	background-color: lime;
}

.red {
	background-color: red;
}
</style>
