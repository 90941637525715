<template>
	<div class="mainToolsContainer">
		<a :href="'//' + `cdnjs.com/libraries/font-awesome`">
			<p>CDN-JS</p>
			<img src="../../assets/Main/cdnjs.png" alt="" />
		</a>
		<a :href="'//' + `fontawesome.com/search?s=solid%2Cbrands`">
			<p>Font Awesome</p>
			<img src="../../assets/Main/fontAwesome.png" alt="" />
		</a>
		<a :href="'//' + `fonts.google.com`">
			<p>Google Fonts</p>
			<img src="../../assets/Main/googleFonts.png" alt="" />
		</a>
		<a :href="'//' + `frontendmentor.io/home`">
			<p>Frontend Mentor</p>
			<img src="../../assets/Main/frontendMentor.jpg" alt="" />
		</a>
	</div>
</template>

<script>
export default {
	name: "MainTools",
};
</script>

<style scoped lang="scss">
@import "../../../scss/_mixins.scss";

.mainToolsContainer {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 2rem;

	@include respond(tab-port) {
		grid-template-columns: 1fr;
	}
}

a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #212121;
	border-radius: 0.8rem;
	padding: 4rem 1.5rem;
	text-decoration: none;

	@include respond(tab-port) {
		padding: 2rem 1.5rem;
	}

	img {
		width: 5rem;
		border-radius: 0.8rem;
	}

	&:hover {
		background-color: #7149e9;

		p {
			color: #212121;
		}
	}
}

p {
	font-size: 2.6rem;
	color: #ffffff;
	font-weight: 400;
}
</style>
