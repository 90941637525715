<template>
	<div class="header-nav">
		<a :href="'//' + `github.com`">
			<img src="../assets/Main/github-brands.svg" alt="GitHub" title="GitHub" />
		</a>
		<a :href="'//' + `elliotlafave.atlassian.net/jira/projects`">
			<img src="../assets/Main/jira.png" alt="Jira" title="Jira" />
		</a>
	</div>
</template>

<script>
export default {
	name: "HeaderNav",
};
</script>

<style scoped lang="scss">
@import "../../scss/_mixins.scss";

.header-nav {
	display: flex;
	align-items: center;
	height: 100%;
	margin-right: 2rem;

	@include respond(tab-port) {
		margin-right: 0;
	}

	a {
		width: 7.5rem;

		@include respond(tab-port) {
			width: 6rem;
		}

		img {
			width: 4.7rem;

			@include respond(tab-port) {
				width: 4rem;
			}
		}
	}
}
</style>
