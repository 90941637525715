<template>
	<div class="container">
		<nav>
			<AppNavigationButton text="Dev Tools" url="/" />
			<AppNavigationButton text="Projects" url="/Projects" />
			<AppNavigationButton text="Quick Copy" url="/Quick-Copy" />
		</nav>
		<div id="secondary">
			<AppNavigationNav />
		</div>
	</div>
</template>

<script>
import AppNavigationButton from "./AppNavigationButton.vue";
import AppNavigationNav from "./AppNavigationNav.vue";

export default {
	name: "AppNavigation",
	components: { AppNavigationButton, AppNavigationNav },
};
</script>

<style scoped lang="scss">
@import "../../scss/_mixins.scss";

.container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include respond(tab-land) {
		flex-direction: column;
		margin-bottom: 1rem;
	}
}

nav {
	display: flex;
	margin-bottom: 2rem;

	@include respond(tab-land) {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin-bottom: 2rem;
	}

	@include respond(tab-port) {
		width: 100%;
		gap: 1rem 0;
		grid-template-columns: 1fr;
		margin-bottom: 1.4rem;
	}
}

.secondary {
	display: flex;
}
</style>
