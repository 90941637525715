<template>
	<footer>
		<div class="tags">
			<div class="tag">
				<Ball :active="ProjectsActive ? true : false" />
				<p>Projects API</p>
			</div>
			<div class="tag">
				<Ball :active="EAuthActive ? true : false" />
				<p>EAuth API</p>
			</div>
			<div class="tag">
				<Ball :active="TasksActive ? true : false" />
				<p>Tasks API</p>
			</div>
		</div>
		<h3>v2.0.18</h3>
	</footer>
</template>

<script>
import Ball from "./Ball.vue";

const PROJECTS_API_URL = "https://elliotapiserver.com/api/v1/projects";
const EAUTH_API_URL = "https://meetmeapi.herokuapp.com/api/v1/users/";
const TASKS_API_URL = "https://elliotapiserver.com/tasks";

export default {
	name: "Footer",
	components: { Ball },
	data() {
		return {
			projectsUrl: PROJECTS_API_URL,
			eauthUrl: EAUTH_API_URL,
			tasksUrl: TASKS_API_URL,
			ProjectsActive: Boolean,
			EAuthActive: Boolean,
			TasksActive: Boolean,
		};
	},
	methods: {
		async checkIfActive(URL) {
			try {
				let res = await fetch(URL);

				if (res.ok) return true;
				return false;
			} catch (err) {
				return false;
			}
		},
	},
	async created() {
		this.ProjectsActive = await this.checkIfActive(this.projectsUrl);
		this.EAuthActive = await this.checkIfActive(this.eauthUrl);
		this.TasksActive = await this.checkIfActive(this.tasksUrl);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../scss/mixins";

.tags {
	display: flex;
	align-items: center;
}

footer {
	z-index: 100000;
	width: 100%;
	grid-column: 1/3;
	background-color: #212121;
	border-top: 1px solid #7149e9;
	padding: 0 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include respond(tab-port) {
		position: absolute;
		bottom: 0;
		height: 2rem;
		padding: 0 0.5rem;
		justify-content: space-between;
	}
}

.tag {
	display: flex;
	margin-right: 2.3rem;

	@include respond(tab-port) {
		margin-right: 1rem;
	}
}

p {
	margin-left: 0.5rem;
	color: #ffffff;
	font-size: 1.2rem;
	line-height: 1.2rem;
	font-weight: 500;
	letter-spacing: 0.8px;

	@include respond(tab-port) {
		font-size: 1rem;
	}
}

h3 {
	font-weight: 400;
	color: #ffffff;
}
</style>
