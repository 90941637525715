<template>
	<div class="toolsTitle">
		<h3>Web Dev Tools</h3>
	</div>
	<div class="toolsContainer">
		<a :href="'//' + `getcssscan.com/css-box-shadow-examples`">
			<p>Box-Shadow</p>
			<img src="../../assets/Main/cssscan.svg" alt="" />
		</a>
		<a :href="'//' + `app.diagrams.net`">
			<p>Flowchart Maker</p>
			<img src="../../assets/Main/diagrams.png" alt="" />
		</a>

		<a :href="'//' + `site-shot.com`">
			<p>Site Shot</p>
			<img src="../../assets/Main/siteshot.png" alt="" />
		</a>
		<a :href="'//' + `cubic-bezier.com`">
			<p>Cubic-Bezier</p>
			<img src="../../assets/Main/cubic.png" alt="" />
		</a>
		<!-- <a :href="'//' + `easings.net/#easeInCirc`">
			<p>Easing Functions</p>
			<img src="../../assets/Main/easings.png" alt="" />
		</a> -->
		<a :href="'//' + `crop-circle.imageonline.co`">
			<p>Crop Circle</p>
			<img src="../../assets/Main/crop-circle.png" alt="" />
		</a>
		<a :href="'//' + `bennettfeely.com/clippy/`">
			<p>Clip Path</p>
			<img src="../../assets/Main/clippath.png" alt="" />
		</a>
		<a :href="'//' + `getwaves.io`">
			<p>Waves Generator</p>
			<img src="../../assets/Main/waves.png" alt="" />
		</a>
		<a :href="'//' + `blobmaker.app`">
			<p>Blob Generator</p>
			<img src="../../assets/Main/blob.png" alt="" />
		</a>
		<a :href="'//' + `keyframes.app/animate`">
			<p>Keyframes</p>
			<img src="../../assets/Main/keyframes.png" alt="" />
		</a>
		<a :href="'//' + `carbon.now.sh`">
			<p>Carbon</p>
			<img src="../../assets/Main/carbon.png" alt="" />
		</a>
	</div>
</template>

<script>
export default {
	name: "WebDevTools",
};
</script>

<style scoped lang="scss">
@import "../../../scss/_mixins.scss";

.toolsTitle {
	margin-top: 2rem;
	margin-bottom: 1rem;

	h3 {
		color: #ffffff;
		font-size: 3.2rem;
		font-weight: 400;
	}
}

.toolsContainer {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 2rem;

	@include respond(tab-port) {
		grid-template-columns: 1fr;
	}
}

a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #212121;
	border-radius: 0.8rem;
	padding: 2rem 1.5rem;
	text-decoration: none;

	img {
		width: 3.4rem;
		border-radius: 0.8rem;
	}

	&:hover {
		background-color: #7149e9;

		p {
			color: #212121;
		}
	}
}

p {
	font-size: 1.9rem;
	color: #ffffff;
	font-weight: 400;
}
</style>
