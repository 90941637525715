<template>
	<div class="ToolsLinksView">
		<MainTools />
		<WebDevTools />
		<Colors />
		<Images />
		<FontsAndIcons />
		<Documentation />
		<Inspiration />
	</div>
</template>

<script>
import MainTools from "@/components/ToolsViewComponents/MainTools.vue";
import WebDevTools from "@/components/ToolsViewComponents/WebDevTools.vue";
import Colors from "@/components/ToolsViewComponents/Colors.vue";
import Images from "@/components/ToolsViewComponents/Images.vue";
import FontsAndIcons from "@/components/ToolsViewComponents/FontsAndIcons.vue";
import Documentation from "@/components/ToolsViewComponents/Documentation.vue";
import Inspiration from "@/components/ToolsViewComponents/Inspiration.vue";

export default {
	name: "ToolsLinksView",
	components: { MainTools, WebDevTools, Colors, Images, FontsAndIcons, Documentation, Inspiration },
};
</script>

<style lang="scss">
@import "../../scss/_mixins.scss";

.ToolsLinksView {
	overflow-y: scroll;
	padding-bottom: 3rem;

	@include respond(tab-port) {
		padding-bottom: 5rem;
	}
}
</style>
