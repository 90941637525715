<template>
	<Header />
	<Sidebar class="sidebar" />
	<main>
		<AppNavigation />
		<router-view />
	</main>
	<Footer />
</template>

<script>
import Header from "./components/header.vue"; // @ is an alias to /src
import Sidebar from "./components/sidebar.vue"; // @ is an alias to /src
import AppNavigation from "./components/AppNavigation.vue";
import Footer from "./components/Footer.vue";

export default {
	name: "HomeView",
	components: {
		Header,
		Sidebar,
		AppNavigation,
		Footer,
	},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import "../scss/_mixins.scss";

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;

	&:focus {
		outline: 2px solid #7149e9;
	}
}

html {
	font-size: 62.5%;
}

body {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	font-family: "poppins";
	background-color: #212529;
}

#app {
	width: 100vw;
	height: 100vh;
	display: grid;
	grid-template-columns: 2fr 8fr;
	grid-template-rows: 9vh 88.5vh 2.5vh;
}

@include respond(tab-port) {
	.sidebar {
		display: none !important;
	}
}

:root {
	--color-primary: hsl(255, 78%, 60%);
	--color-grey-dark: #212529;
}
$color-primary: var(--color-primary);
$color-grey-dark: var(--color-grey-dark);

::-webkit-scrollbar {
	width: 18px;
	margin: 1px;
}

::-webkit-scrollbar-track {
	background: rgba($color-grey-dark, 0.9);
}

::-webkit-scrollbar-thumb {
	background: $color-primary;
	border-radius: 100vw;
	border: 3px solid $color-grey-dark;

	&:hover {
		background: #7c50ff;
	}
}

@supports (scrollbar-color: red blue) {
	* {
		scrollbar-color: $color-primary $color-grey-dark;
	}
}
</style>

<style scoped lang="scss">
@import "../scss/_mixins.scss";

main {
	padding: 1.7rem 2rem;
	display: flex;
	flex-direction: column;

	@include respond(tab-port) {
		grid-column: 1/3;
	}

	@include respond(mini-phone) {
		width: 100vw;
	}
}
</style>
