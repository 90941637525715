<template>
	<div class="container">
		<div class="left-side">
			<div v-if="dataRecieved && success">
				<h2>Posted!</h2>
				<i class="fa-solid fa-check"></i>
			</div>
			<div v-if="dataRecieved && !success">
				<h2>An error occoured!</h2>
				<i class="fa-solid fa-hexagon-exclamation"></i>
			</div>
			<div v-if="dataPosted && !dataRecieved">
				<h2>Posting</h2>

				<LoadingSpinner />
			</div>
			<div v-if="!dataPosted">
				<h2>Post new project</h2>
				<form @submit="onSubmit">
					<ErrorText :text="passwordIncorrect" />
					<input type="password" v-model="projectsApiPassword" name="password" placeholder="Password" />
					<button type="submit" class="btn">Post Card<i class="fa-solid fa-arrow-right"></i></button>
				</form>
			</div>
		</div>
		<div class="right-side">
			<ProjectCard :project="project" />
		</div>
	</div>
</template>

<script>
import ProjectCard from "../components/ProjectsComponents/ProjectsCard.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ErrorText from "../components/ErrorText.vue";

export default {
	name: "PostNewCard",
	components: { ProjectCard, LoadingSpinner, ErrorText },
	data() {
		return {
			passwordIncorrect: "",
			projectsApiPassword: undefined,
			dataPosted: false,
			success: undefined,
			dataRecieved: false,
			project: {
				title: String,
				date: String,
				paragraph: String,
				linkLivePreview: String,
				linkGit: String,
				linkImg: String,
				tags: [],
			},
		};
	},

	methods: {
		async onSubmit(e) {
			e.preventDefault();
			console.log("Post to api");
			if (!this.projectsApiPassword) {
				alert("Please Type a Password");
				return;
			}

			this.dataPosted = true;
			let req = {
				...this.project,
				auth: {
					id: "62e648ad4d2501969a071da1",
					password: this.projectsApiPassword,
				},
			};

			console.log(JSON.stringify(req));

			try {
				await fetch("https://elliotapiserver.com/api/v1/projects", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(req),
				})
					.then((data) => data.json())
					.then((jsonData) => {
						console.log(jsonData);
						this.dataRecieved = true;
						if (jsonData.status === "success") {
							this.success = true;
						} else if (jsonData.message === "Incorrect Password") {
							console.log("incorrectPassword");
							this.dataRecieved = false;
							this.dataPosted = false;
							this.success = false;
							this.passwordIncorrect = jsonData.message;
						} else {
							this.success = false;
						}
					});
			} catch (err) {
				console.log({ err });
			}
		},
	},
	created() {
		if (
			!this.$route.params.title ||
			!this.$route.params.date ||
			!this.$route.params.paragraph ||
			!this.$route.params.linkLivePreview ||
			!this.$route.params.linkGit ||
			!this.$route.params.linkImg ||
			!this.$route.params.tags
		) {
			this.$router.push("/NewCard");
		}
		this.project.title = this.$route.params.title;
		this.project.date = this.$route.params.date;
		this.project.paragraph = this.$route.params.paragraph;
		this.project.linkLivePreview = this.$route.params.linkLivePreview;
		this.project.linkGit = this.$route.params.linkGit;
		this.project.linkImg = this.$route.params.linkImg;
		this.project.tags = this.$route.params.tags;

		console.log(this.project);
	},
};
</script>

<style></style>

<style scoped lang="scss">
@import "../../scss/_mixins.scss";
@import "../../scss/variables";

i {
	color: $text-light;
	font-size: 3rem;
}

.container {
	padding: 2rem 0 0 0;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;

	@include respond(tab-land) {
		grid-template-columns: 1fr;
		overflow-y: scroll;
	}
}

h2 {
	font-size: 2.7rem;
	color: #ffffff;
	font-weight: 500;
	margin-bottom: 2rem;
}

form {
	display: flex;
	flex-direction: column;
	align-items: center;

	@include respond(tab-port) {
		width: 100%;
		margin-top: 1.4rem;
		flex-grow: 10%;
	}

	button {
		background-color: #7159e9;
		border-radius: 0.5rem;
		border: none;
		font-size: 1.8rem;
		color: $text-light;
		padding: 1rem 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			background-color: #ffffff;
			color: #212121;
			cursor: pointer;
		}

		i {
			margin-left: 1rem;
		}
	}

	input {
		height: 100%;
		width: 28ch;
		border-radius: 0.6rem;
		padding: 0.5rem 1rem;
		background-color: #212121;
		border: none;
		color: #ffffff;
		line-height: 2rem;
		font-size: 2rem;
		margin-bottom: 2rem;

		@include respond(tab-port) {
			width: 100%;
		}
	}
}

.left-side {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 15rem;
}

.right-side {
	padding: 0 15rem;

	@include respond(laptop) {
		padding: 0 15vw;
		margin-top: 5rem;
	}
}
.checkboxes {
	display: flex;
}
.form-control {
	display: flex;
	flex-direction: column;

	&-check {
		margin-right: 1.5rem;
		margin-bottom: 2rem;

		label {
			color: #ffffff;
			font-size: 1.6rem;
			margin-right: 0.5rem;
		}
	}

	label {
		color: #ffffff;
		font-size: 2.3rem;
	}

	input {
		height: 100%;
		border-radius: 0.6rem;
		padding: 0.5rem 1rem;
		background-color: #212121;
		border: none;
		color: #ffffff;
		line-height: 2rem;
		font-size: 2rem;
		margin-right: 1rem;
		margin-bottom: 1.6rem;

		@include respond(tab-port) {
			width: 100%;
		}
	}
	textarea {
		height: 100%;
		border-radius: 0.6rem;
		padding: 0.5rem 1rem;
		background-color: #212121;
		border: none;
		color: #ffffff;
		line-height: 2rem;
		font-size: 2rem;
		margin-right: 1rem;
		margin-bottom: 1.6rem;
		resize: none;

		@include respond(tab-port) {
			width: 100%;
		}
	}
}
</style>
