<template>
	<header>
		<div>
			<a :href="'//' + `elliotlafave.com/portfolio#all-projects`" target="_blank">
				<img src="../assets/logo.png" alt="" />
			</a>
		</div>
		<HeaderNav />
	</header>
</template>

<script>
import HeaderNav from "./HeaderNav.vue";

export default {
	name: "Header",
	components: {
		HeaderNav,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
header {
	width: 100vw;
	grid-column: 1/3;
	display: flex;
	background-color: #212121;
	padding: 0.5rem 0;
	border-bottom: 1px solid #7149e9;
	display: flex;
	justify-content: space-between;

	img {
		height: 100%;
		margin-left: 1.2rem;
	}
}
</style>
