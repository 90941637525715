<template>
	<div class="toolsTitle">
		<h3>Images</h3>
	</div>
	<div class="toolsContainer">
		<a :href="'//' + `unsplash.com`">
			<p>Unsplash</p>
			<img src="../../assets/Main/unsplash.png" alt="" />
		</a>
		<a :href="'//' + `undraw.co/search`">
			<p>Undraw</p>
			<img src="../../assets/Main/unDraw.png" alt="" />
		</a>
		<a :href="'//' + `uigoodies.com/?category=Illustrations`">
			<p>UI Goodies</p>
			<img src="../../assets/Main/uiGoodies.png" alt="" />
		</a>
		<a :href="'//' + `coverr.co`">
			<p>Stock Video</p>
			<img src="../../assets/Main/coverr.png" alt="" />
		</a>
		<a :href="'//' + `dribbble.com`">
			<p>Dribble</p>
			<img src="../../assets/Main/dribble.png" alt="" />
		</a>
		<a :href="'//' + `pixabay.com`">
			<p>Pixabay</p>
			<img src="../../assets/Main/pixabay.png" alt="" />
		</a>
	</div>
</template>

<script>
export default {
	name: "Images",
};
</script>

<style scoped lang="scss">
@import "../../../scss/_mixins.scss";

.toolsTitle {
	margin-top: 2rem;
	margin-bottom: 1rem;

	h3 {
		color: #ffffff;
		font-size: 3.2rem;
		font-weight: 400;
	}
}

.toolsContainer {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 2rem;

	@include respond(tab-port) {
		grid-template-columns: 1fr;
	}
}

a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #212121;
	border-radius: 0.8rem;
	padding: 2rem 1.5rem;
	text-decoration: none;

	img {
		width: 3.4rem;
		border-radius: 0.8rem;
	}

	&:hover {
		background-color: #7149e9;

		p {
			color: #212121;
		}
	}
}

p {
	font-size: 1.9rem;
	color: #ffffff;
	font-weight: 400;
}
</style>
