<template>
	<div class="container">
		<div v-if="dataRecieved && success">
			<h2>Deleted!</h2>
			<i class="fa-solid fa-check"></i>
		</div>
		<div v-if="dataRecieved && !success">
			<h2>An error occoured!</h2>
			<i class="fa-solid fa-hexagon-exclamation"></i>
		</div>
		<div v-if="dataPosted && !dataRecieved">
			<h2>Deleting...</h2>

			<LoadingSpinner />
		</div>
		<div v-if="!dataPosted">
			<h2>Deleting Project</h2>
			<form @submit="onSubmit">
				<ErrorText :text="passwordIncorrect" />
				<input type="password" v-model="projectsApiPassword" name="password" placeholder="Password" />
				<button type="submit" class="btn">Delete Card<i class="fa-solid fa-arrow-right"></i></button>
			</form>
		</div>
	</div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ErrorText from "@/components/ErrorText.vue";
export default {
	name: "DeleteCard",
	data() {
		return {
			id: null,
			dataPosted: false,
			dataRecieved: false,
			success: false,
			passwordIncorrect: "",
			projectsApiPassword: undefined,
		};
	},
	methods: {
		async onSubmit(e) {
			e.preventDefault();
			console.log("Post to api");
			if (!this.projectsApiPassword) {
				alert("Please Type a Password");
				return;
			}
			this.dataPosted = true;
			let req = {
				id: this.id,
				auth: {
					id: "62e648ad4d2501969a071da1",
					password: this.projectsApiPassword,
				},
			};
			console.log(JSON.stringify(req));
			try {
				await fetch("https://elliotapiserver.com/api/v1/projects", {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(req),
				})
					.then((data) => data.json())
					.then((jsonData) => {
						console.log(jsonData);
						this.dataRecieved = true;
						if (jsonData.status === "success") {
							console.log("successful");
							this.success = true;
						} else if (jsonData.message === "Incorrect Password") {
							console.log("incorrectPassword");
							this.dataRecieved = false;
							this.dataPosted = false;
							this.success = false;
							this.passwordIncorrect = jsonData.message;
						} else {
							console.log("failed");

							this.success = false;
						}
					});
			} catch (err) {
				console.log(err);
			}
		},
	},
	created() {
		if (!this.$route.params.id) {
			this.$router.push("/Projects");
		}
		this.id = this.$route.params.id;
	},
	components: { LoadingSpinner, ErrorText },
};
</script>

<style scoped lang="scss">
@import "../../scss/_mixins.scss";
@import "../../scss/variables";

i {
	color: $text-light;
	font-size: 3rem;
}

.container {
	margin-top: 10vh;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 15rem;
}

h2 {
	font-size: 2.7rem;
	color: #ffffff;
	font-weight: 500;
	margin-bottom: 2rem;
}

h2 {
	font-size: 2.7rem;
	color: #ffffff;
	font-weight: 500;
	margin-bottom: 2rem;
}

form {
	display: flex;
	flex-direction: column;
	align-items: center;

	@include respond(tab-port) {
		width: 100%;
		margin-top: 1.4rem;
		flex-grow: 10%;
	}

	button {
		background-color: #7159e9;
		border-radius: 0.5rem;
		border: none;
		font-size: 1.8rem;
		color: $text-light;
		padding: 1rem 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			background-color: #ffffff;
			color: #212121;
			cursor: pointer;
		}

		i {
			margin-left: 1rem;
		}
	}

	input {
		height: 100%;
		width: 28ch;
		border-radius: 0.6rem;
		padding: 0.5rem 1rem;
		background-color: #212121;
		border: none;
		color: #ffffff;
		line-height: 2rem;
		font-size: 2rem;
		margin-bottom: 2rem;

		@include respond(tab-port) {
			width: 100%;
		}
	}
}
</style>
