<template>
	<nav>
		<div class="nav-top">
			<router-link class="btn" text="Web Dev Tools" to="/WebDevTools" />
			<router-link class="btn" text="Colors" to="/Colors" />
			<router-link class="btn" text="Images" to="/Images" />
			<router-link class="btn" text="Fonts & Icons" to="/FontsAndIcons" />
			<router-link class="btn" text="Documentation" to="/Documentation" />
			<router-link class="btn" text="Inspiration" to="Inspiration" />
		</div>
		<div class="nav-bottom">
			<router-link class="btn purple" text="Add Card" to="NewCard" />
		</div>
	</nav>
</template>

<script>
import Button from "./Button.vue";

export default {
	name: "Sidebar",
	components: {
		Button,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
nav {
	background-color: #212121;
	padding: 2rem;
	border-right: 1px solid #7149e9;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.btn {
	background-color: #212529;
	padding: 1.3rem 0;
	margin-bottom: 1.8rem;
	border-radius: 0.5rem;

	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	font-size: 2.2rem;
	color: #ffffff;
	font-weight: 600;
	letter-spacing: 1px;

	&:hover {
		outline: 2px solid #7149e9;
	}
}

.purple {
	background-color: #7149e9;
}
</style>
