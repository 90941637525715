<template>
	<div class="app-nav">
		<a :href="'//' + `dash.cloudflare.com/208569baf8eaa845bd735825e52ade31/elliotlafave.com`">
			<img
				srcset="https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Cloudflare_Logo.png/1024px-Cloudflare_Logo.png"
				alt="Cloudflare logo"
				title="Cloudflare"
			/>
		</a>
		<a class="smaller" :href="'//' + `console.firebase.google.com`">
			<img srcset="https://img.icons8.com/color/96/firebase.png 2x" alt="Firebase logo" title="Firebase" />
		</a>
		<a :href="'//' + `app.sendgrid.com/`">
			<img src="../assets/Main/SendGrid.png" alt="SendGrid" title="SendGrid" />
		</a>
		<a class="smaller" :href="'//' + `figma.com/files/recent?fuid=728012180329556647`">
			<img class="smaller" src="../assets/Main/figma.png" alt="Figma" title="Figma" />
		</a>
		<a :href="'//' + `app.netlify.com/teams/elliotlafave123/overview`">
			<img src="../assets/Main/netlify.svg" alt="Netlify" title="Netlify" />
		</a>
		<a :href="'//' + `cloud.linode.com/linodes`">
			<img src="../assets/Main/linode.png" alt="Linode" title="Linode" />
		</a>
		<a :href="'//' + `id.heroku.com/login`">
			<img class="round" src="../assets/Main/heroku.png" alt="Heroku" title="Heroku" />
		</a>
		<a :href="'//' + `account.mongodb.com/account/login?n=%2Fv2%2F6284b301760ff60a2a93b8ed&nextHash=%23clusters`">
			<img class="round" src="../assets/Main/mongodb.png" alt="MongoDB" title="MongoDB" />
		</a>
	</div>
</template>

<script>
export default {
	name: "AppNavigationNav",
};
</script>

<style scoped lang="scss">
@import "../../scss/_mixins.scss";

.app-nav {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	height: 100%;
	margin-right: 1.3rem;
	transition: all 0.2s ease-in-out;

	@include respond(tab-port) {
		margin-right: 0;
	}

	a {
		width: 6rem;

		@include respond(mini-phone) {
			width: 5rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		img,
		svg {
			height: 3.9rem;
			// width: 3.9rem;

			@include respond(mini-phone) {
				height: 3rem;
			}

			&.smaller {
				height: 3.3rem;

				@include respond(mini-phone) {
					height: 2.6rem;
				}
			}

			&:hover {
				transform: scale(1.03);
			}
		}

		&.smaller {
			width: 4.7rem;

			@include respond(mini-phone) {
				height: 3.8rem;
			}

			&:hover {
				transform: scale(1.03);
			}
		}
	}
}

.round {
	border-radius: 50%;
}
</style>
