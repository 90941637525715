<template>
	<router-link :to="url" class="btn">
		{{ text }}
	</router-link>
</template>

<script>
export default {
	name: "Button",
	props: {
		text: String,
		url: String,
	},
};
</script>

<style scoped lang="scss">
@import "../../scss/_mixins.scss";

.btn {
	background-color: #212529;
	padding: 0.8rem 2.5rem;
	margin-right: 1rem;
	border-radius: 0.5rem;
	border: 2px solid #7149e9;

	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	font-size: 2.2rem;
	color: #ffffff;
	font-weight: 600;
	letter-spacing: 1px;

	@include respond(tab-port) {
		padding: 0.5rem 0.5rem;
		margin-right: 0;
		font-size: 1.3rem;

		&:not(:last-child) {
			margin-right: 0rem;
		}
	}

	@include respond(mini-phone) {
		padding: 0.2rem 0;
		margin-right: 0;
		font-size: 1.2rem;

		&:not(:last-child) {
			margin-right: 0;
		}
	}

	&:hover {
		outline: 2px solid #7149e9;
	}
}
</style>
