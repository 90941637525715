<template>
	<section class="ProjectsAppContainer">
		<div :key="project._id" v-for="project in projects">
			<ProjectCard deleteable="true" :project="project" />
		</div>
	</section>
</template>
<!-- @delete-project="deleteProject" -->
<script>
import ProjectCard from "../components/ProjectsComponents/ProjectsCard.vue";

export default {
	name: "ToolsLinksView",
	components: { ProjectCard },
	data() {
		return { projects: [] };
	},
	methods: {
		async fetchProjects() {
			try {
				const res = await fetch("https://elliotapiserver.com/api/v1/projects");
				const data = await res.json();
				return data;
			} catch (err) {
				console.log(err);
			}
		},
	},
	async created() {
		this.projects = await this.fetchProjects();
	},
};
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "../../scss/mixins";

.hide-mobile {
	display: block;
	@include respond(tab-port) {
		display: none;
	}
}

.u-align-self {
	align-self: center;
	justify-self: center;
}

.hidden {
	display: none;
}

.blur {
	filter: blur(0.1rem);
}

.invisible {
	display: none;
	visibility: hidden;
}

.ProjectsAppContainer {
	width: 100%;
	display: grid;
	padding: 0;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	gap: 3rem;
	position: relative;
	overflow-y: scroll;

	@include respond(large-desktop) {
		grid-template-columns: repeat(5, 1fr);
	}
}

.ProjectsAppInputs {
	padding: 1.7rem 0.2rem;
	display: flex;
	justify-content: space-between;

	&-side {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	a {
		color: $color-primary-light;
		font-size: 1.6rem;
		letter-spacing: 0.3px;
		text-decoration: underline;
		margin-right: 2rem;
		transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

		&:hover {
			text-decoration: none;
			color: white;
			cursor: pointer;
		}
	}
}

.ProjectsAppInputsBottom {
	width: 100%;
	height: 4.4rem;
	margin-top: 1.7rem;
}

.ProjectsAppNotFound {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	height: 95vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: -1;

	img {
		width: 30rem;
	}

	p {
		width: 30rem;
		margin-top: 4rem;
		text-align: center;
		font-size: 2rem;
		line-height: 3rem;
	}
}

.ProjectsAppPagination {
	font-size: 1.6rem;
	height: 100%;
	margin-left: 3rem;
	display: flex;

	&-bottom {
		margin-left: 0;
		height: 4.4rem;
		display: flex;
	}

	&-button {
		color: $text-light;
		height: 100%;
		background-color: $color-card;
		border: none;
		padding: 0 1.5rem;
		transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

		&:hover {
			cursor: pointer;
			background-color: $text-light;
			color: $text-dark;
		}

		&-last {
			border-radius: 5px 0 0 5px;
			border-right: 1px solid;
			border-image: linear-gradient($color-card, $color-card, $text-light, $color-card, $color-card) 0 100%;
			&:hover {
				border-image: $color-card;
			}
		}

		&-next {
			border-radius: 0 5px 5px 0;
			border-left: 1px solid;
			border-image: linear-gradient($color-card, $color-card, $text-light, $color-card, $color-card) 0 100%;
			&:hover {
				border-image: $color-card;
			}
		}
	}

	&-number {
		color: $text-light;
		height: 100%;
		background-color: $color-card;
		border: none;
		padding: 0 1.5rem 0 1.5rem;
		width: 7rem;
	}
}

/* Search Bar */
.search {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	margin: 4rem 0 2rem 0vw;

	@include respond(tab-port) {
		flex-direction: column;
		align-items: start;
	}
}

.search-input {
	width: 28vw;
	border: none;
	outline: none;
	cursor: text;
	background-color: $color-card;
	padding: 1rem 3rem 1rem 3rem;
	font-size: 2rem;
	font-weight: 500;
	letter-spacing: 2px;
	color: rgba(255, 255, 255, 0.931);
	border-radius: 5px;

	transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

	@include respond(tab-port) {
		width: 100%;
		margin-bottom: 2rem;
	}

	@include respond(phone) {
		width: 100%;
		margin-bottom: 2rem;
		color: rgba(255, 255, 255, 0.931);
	}

	&::placeholder {
		opacity: 0.9; /* Firefox */
		letter-spacing: 1px;
		font-weight: 500;
		font-size: 2rem;
		color: rgba(255, 255, 255, 0.931);
	}
}

#dropdown {
	height: 100%;
	width: 12rem;
	padding: 1rem 2rem 1rem 2rem;
	background-color: $color-card;
	border: none;
	border-radius: 5px;
	color: #fff;

	@include respond(tab-port) {
		width: 40%;
	}

	&:hover {
		cursor: pointer;
	}
}
</style>
