<template>
	<div class="copy-container">
		<div :class="[unlocked ? 'full-width' : 'locked']" class="password-container">
			<p :class="[unlocked ? '' : 'locked']">{{ TokenPlaceholder }}</p>
			<button :class="[unlocked ? '' : 'locked']" class="btncopy" v-if="copied" tabindex="-1">
				<i class="fa-solid fa-check" :class="[unlocked ? '' : 'locked']"></i>
			</button>
			<button class="btncopy focusable" v-else-if="unlocked" @click="copyText">
				<i class="fa-solid fa-copy"></i>
			</button>
			<button class="btncopy notAllowed" v-else tabindex="-1">
				<i class="fa-solid fa-lock"></i>
			</button>
		</div>
		<form v-if="!unlocked" @submit="onSubmit">
			<input type="password" v-model="githubTokenPassword" name="text" placeholder="Password" />
			<button type="submit" class="btn"><i class="fa-solid fa-arrow-right"></i></button>
		</form>
	</div>
</template>

<script>
export default {
	name: "AuthenticationKey",
	props: {
		tokenPlaceholder: String,
		tokenID: String,
	},
	data() {
		return {
			TokenPlaceholder: this.tokenPlaceholder,
			TokenId: this.tokenID,
			githubTokenPassword: undefined,
			unlocked: false,
			copied: false,
		};
	},
	methods: {
		copyText() {
			if (this.unlocked) {
				navigator.clipboard.writeText(this.TokenPlaceholder);
				this.copied = true;
			} else {
				console.log("Unlock the key before copying");
			}
		},
		async onSubmit(e) {
			e.preventDefault();

			if (!this.githubTokenPassword) {
				alert("Please Type a Password");
				return;
			}
			if (this.unlocked) return;

			const req = {
				_id: this.TokenId,
				password: this.githubTokenPassword,
			};

			try {
				await fetch("https://elliotapiserver.com/secureStringReciever/Get", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(req),
				})
					.then((data) => data.json())
					.then((jsonData) => {
						if (jsonData.status === "Success") {
							this.unlocked = true;
							this.TokenPlaceholder = jsonData.data.string;
						}
					});
			} catch (err) {
				console.log(err);
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import "../../../scss/_mixins.scss";

.locked {
	cursor: not-allowed !important;

	i {
		cursor: not-allowed !important;
	}
}

.copy-container {
	margin-bottom: 2rem;
	width: 100%;
	display: flex;

	@include respond(tab-port) {
		flex-direction: column;
	}
}

.password-container {
	width: 30%;
	height: 100%;
	border-radius: 0.6rem;
	padding: 1rem 1.3rem;
	background-color: #212121;
	margin-right: 2rem;
	display: flex;
	justify-content: space-between;

	&.full-width {
		width: max-content;
		margin-right: 0;

		@include respond(tab-port) {
			width: 100%;
		}
	}

	@include respond(laptop) {
		width: 100%;
		margin-right: 2rem;
	}

	@include respond(tab-port) {
		width: 100%;
		margin-right: 0;
	}

	.btncopy {
		background-color: transparent;
		border: none;
		color: #ffffff;
		font-size: 1.8rem;
		margin-left: 2rem;

		&:focus {
			outline: none;
		}
	}

	p {
		color: #ffffff;
		font-size: 2rem;
		line-height: 2rem;
	}
}

.focusable {
	&:focus {
		outline: 2px solid #7159e9 !important;
	}
}

form {
	width: 50%;
	display: flex;

	@include respond(tab-port) {
		width: 100%;
		margin-top: 1.4rem;
		flex-grow: 10%;
	}

	input {
		height: 100%;
		border-radius: 0.6rem;
		padding: 0.5rem 1rem;
		background-color: #212121;
		border: none;
		color: #ffffff;
		line-height: 2rem;
		font-size: 2rem;
		margin-right: 1rem;

		@include respond(tab-port) {
			width: 100%;
		}
	}
}

.btn {
	border: none;
	background-color: #7149e9;
	color: #212529;
	padding: 0 1rem;
	border-radius: 0.6rem;
	line-height: 2rem;
	font-size: 2rem;
	cursor: pointer;

	i {
		font-size: 2rem;
	}
}
</style>
