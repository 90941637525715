<template>
	<div class="toolsTitle">
		<h3>Documentation</h3>
	</div>
	<div class="toolsContainer">
		<a :href="'//' + ``">
			<p>MDN Docs</p>
			<img src="../../assets/Main/mdn.jpeg" alt="" />
		</a>
		<a :href="'//' + ``">
			<p>W3 Schools</p>
			<img src="../../assets/Main/w3.jpg" alt="" />
		</a>
		<a :href="'//' + ``">
			<p>Language Codes</p>
			<img src="../../assets/Main/language.png" alt="" />
		</a>
		<a :href="'//' + ``">
			<p>Unicode</p>
			<img src="../../assets/Main/unicode.jpg" alt="" />
		</a>

		<a :href="'//' + ``">
			<p>Emmet</p>
			<img src="../../assets/Main/emmet.png" alt="" />
		</a>
		<a :href="'//' + ``">
			<p>Design Patterns</p>
			<img src="../../assets/Main/designPatterns.jpeg" alt="" />
		</a>
		<a :href="'//' + ``">
			<p>Node.js</p>
			<img src="../../assets/Main/nodejs.png" alt="" />
		</a>
		<a :href="'//' + ``">
			<p>Express</p>
			<img src="../../assets/Main/express.png" alt="" />
		</a>
		<a :href="'//' + ``">
			<p>MongoDD</p>
			<img src="../../assets/Main/mongodb.jpeg" alt="" />
		</a>
		<a :href="'//' + ``">
			<p>Mongoose</p>
			<img src="../../assets/Main/mongoose.jpg" alt="" />
		</a>
	</div>
</template>

<script>
export default {
	name: "Documentation",
};
</script>

<style scoped lang="scss">
@import "../../../scss/_mixins.scss";

.toolsTitle {
	margin-top: 2rem;
	margin-bottom: 1rem;

	h3 {
		color: #ffffff;
		font-size: 3.2rem;
		font-weight: 400;
	}
}

.toolsContainer {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 2rem;

	@include respond(tab-port) {
		grid-template-columns: 1fr;
	}
}

a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #212121;
	border-radius: 0.8rem;
	padding: 2rem 1.5rem;
	text-decoration: none;

	img {
		width: 3.4rem;
		border-radius: 0.8rem;
	}

	&:hover {
		background-color: #7149e9;

		p {
			color: #212121;
		}
	}
}

p {
	font-size: 1.9rem;
	color: #ffffff;
	font-weight: 400;
}
</style>
