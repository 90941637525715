<template>
	<div class="container">
		<h2>Quick Copy</h2>
		<FetchSecureString tokenPlaceholder="GitHub Token" tokenID="62e528afb63a42d00d024ee2" />
		<FetchMultiLineString tokenPlaceholder="Open SSH Private Key" tokenID="62e5806bc386d089871529d3" />
		<FetchMultiLineString tokenPlaceholder="SSH RSA" tokenID="62e57fadc386d089871529c5" />
	</div>
</template>

<script>
import FetchSecureString from "../components/QuickCopy/FetchSecureString.vue";
import FetchMultiLineString from "../components/QuickCopy/FetchMultiLineString.vue";

export default {
	name: "Quick-Copy",
	components: { FetchSecureString, FetchMultiLineString },
};
</script>

<style scoped lang="scss">
.container {
	overflow-y: scroll;
	width: 100%;
	padding-bottom: 5rem;
}

h2 {
	font-size: 2.7rem;
	color: #ffffff;
	font-weight: 500;
	margin-bottom: 2rem;
}
</style>
