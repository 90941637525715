<template>
	<p class="errorText">{{ text }}</p>
</template>

<script>
export default {
	name: "ErrorText",
	props: {
		text: String,
	},
};
</script>

<style lang="scss" scoped>
.errorText {
	color: red;
	font-size: 1.8rem;
	margin-bottom: 1.2rem;
}
</style>
