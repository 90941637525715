import { createRouter, createWebHashHistory } from "vue-router";
import ToolsLinksView from "../views/ToolsLinksView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import QuickCopy from "../views/QuickCopyView.vue";
import WebDevTools from "../components/ToolsViewComponents/WebDevTools.vue";
import Colors from "../components/ToolsViewComponents/Colors.vue";
import Images from "../components/ToolsViewComponents/Images.vue";
import FontsAndIcons from "../components/ToolsViewComponents/FontsAndIcons.vue";
import Documentation from "../components/ToolsViewComponents/Documentation.vue";
import Inspiration from "../components/ToolsViewComponents/Inspiration.vue";
import NewCard from "../views/NewCard.vue";
import PostNewCard from "../views/PostNewCard.vue";
import DeleteCard from "../views/DeleteCardView.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: ToolsLinksView,
	},
	{
		path: "/Projects",
		name: "projects",
		component: ProjectsView,
	},
	{
		path: "/Quick-Copy",
		name: "ProjectURLsView",
		component: QuickCopy,
	},
	{
		path: "/WebDevTools",
		name: "WebDevTools",
		component: WebDevTools,
	},
	{
		path: "/Colors",
		name: "Colors",
		component: Colors,
	},
	{
		path: "/Images",
		name: "Images",
		component: Images,
	},
	{
		path: "/FontsAndIcons",
		name: "FontsAndIcons",
		component: FontsAndIcons,
	},
	{
		path: "/Documentation",
		name: "Documentation",
		component: Documentation,
	},
	{
		path: "/Inspiration",
		name: "Inspiration",
		component: Inspiration,
	},
	{
		path: "/NewCard",
		name: "NewCard",
		component: NewCard,
	},
	{
		path: "/PostNewCard",
		name: "PostNewCard",
		component: PostNewCard,
	},
	{
		path: "/DeleteCard",
		name: "DeleteCard",
		component: DeleteCard,
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
