<template>
	<a class="btn">
		{{ text }}
	</a>
</template>

<script>
export default {
	name: "Button",
	props: {
		text: String,
		url: String,
	},
};
</script>

<style scoped lang="scss">
.btn {
	background-color: #212529;
	padding: 1.3rem 0;
	margin-bottom: 1.8rem;
	border-radius: 0.5rem;

	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	font-size: 2.2rem;
	color: #ffffff;
	font-weight: 600;
	letter-spacing: 1px;

	&:hover {
		outline: 2px solid #7149e9;
	}
}
</style>
