<template>
	<div class="toolsTitle">
		<h3>Inspiration</h3>
	</div>
	<div class="toolsContainer">
		<a :href="'//' + `goodbrief.io`">
			<p>Design Briefs</p>
			<img src="../../assets/Main/gb.png" alt="" />
		</a>
		<a :href="'//' + `behance.net`">
			<p>Behance</p>
			<img src="../../assets/Main/behance.svg" alt="" />
		</a>
		<a :href="'//' + `100dayscss.com/days/1`">
			<p>100 Days CSS</p>
			<img src="../../assets/Main/100DaysCss.png" alt="" />
		</a>
	</div>
</template>

<script>
export default {
	name: "Inspiration",
};
</script>

<style scoped lang="scss">
@import "../../../scss/_mixins.scss";

.toolsTitle {
	margin-top: 2rem;
	margin-bottom: 1rem;

	h3 {
		color: #ffffff;
		font-size: 3.2rem;
		font-weight: 400;
	}
}

.toolsContainer {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 2rem;

	@include respond(tab-port) {
		grid-template-columns: 1fr;
	}
}

a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #212121;
	border-radius: 0.8rem;
	padding: 2rem 1.5rem;
	text-decoration: none;

	img {
		width: 3.4rem;
		border-radius: 0.8rem;
	}

	&:hover {
		background-color: #7149e9;

		p {
			color: #212121;
		}
	}
}

p {
	font-size: 1.9rem;
	color: #ffffff;
	font-weight: 400;
}
</style>
