<template>
	<div class="container">
		<div class="left-side">
			<form>
				<div class="form-control">
					<label>Title</label>
					<input type="text" v-model="this.project.title" name="title" placeholder="Project Title" />
				</div>
				<div class="form-control">
					<label>Paragraph</label>
					<textarea type="textarea" v-model="this.project.paragraph" name="paragraph" placeholder="Paragraph" />
				</div>
				<div class="form-control">
					<label>Link Live Preview</label>
					<input type="text" v-model="this.project.linkLivePreview" name="linkLivePreview" />
				</div>
				<div class="form-control">
					<label>Link GitHub</label>
					<input type="text" v-model="this.project.linkGit" name="linkGit" />
				</div>
				<div class="form-control">
					<label>Link Image</label>
					<input type="text" v-model="this.project.linkImg" name="linkImg" />
				</div>

				<div class="checkboxes">
					<div class="form-control-check">
						<label>html</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.html" name="html" />
					</div>
					<div class="form-control-check">
						<label>css</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.css" name="css" />
					</div>
					<div class="form-control-check">
						<label>js</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.js" name="js" />
					</div>
					<div class="form-control-check">
						<label>scss</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.scss" name="scss" />
					</div>
					<div class="form-control-check">
						<label>api</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.api" name="api" />
					</div>
					<div class="form-control-check">
						<label>course</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.course" name="course" />
					</div>
					<div class="form-control-check">
						<label>challenge</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.challenge" name="challenge" />
					</div>
					<div class="form-control-check">
						<label>mongodb</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.mongodb" name="mongodb" />
					</div>
					<div class="form-control-check">
						<label>nodejs</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.nodejs" name="nodejs" />
					</div>
					<div class="form-control-check">
						<label>react</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.react" name="react" />
					</div>
					<div class="form-control-check">
						<label>firebase</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.firebase" name="firebase" />
					</div>
					<div class="form-control-check">
						<label>vue</label>
						<input type="checkbox" @change="ProcessTags()" v-model="this.tags.vue" name="vue" />
					</div>
				</div>

				<button @click.stop.prevent="onSubmit" class="btn">Add New Project<i class="fa-solid fa-arrow-right"></i></button>
			</form>
		</div>
		<div class="right-side">
			<ProjectCard :project="project" />
		</div>
	</div>
</template>

<script>
import ProjectCard from "../components/ProjectsComponents/ProjectsCard.vue";

export default {
	name: "NewCard",
	components: { ProjectCard },
	props: {
		updating: false,
	},
	data() {
		return {
			project: {
				title: this.title,
				date: new Date().toISOString(),
				paragraph: this.paragraph,
				linkLivePreview: "https://elliotlafave.com/Projects/",
				linkGit: this.linkGit,
				linkImg: "https://elliotlafave.com/img/Projects/",
				tags: [],
			},
			tags: {
				html: true,
				css: false,
				js: true,
				scss: true,
				api: false,
				course: false,
				challenge: false,
				mongodb: false,
				nodejs: false,
				react: false,
				firebase: false,
				vue: false,
			},
		};
	},
	methods: {
		onSubmit(e) {
			e.preventDefault();
			this.ProcessTags();
			this.$router.push({
				name: "PostNewCard",
				params: {
					title: this.project.title,
					date: this.project.date,
					paragraph: this.project.paragraph,
					linkLivePreview: this.project.linkLivePreview,
					linkGit: this.project.linkGit,
					linkImg: this.project.linkImg,
					tags: this.project.tags,
				},
			});
		},
		ProcessTags() {
			let tags = [];

			this.tags.html ? tags.push("html") : null;
			this.tags.css ? tags.push("css") : null;
			this.tags.js ? tags.push("js") : null;
			this.tags.scss ? tags.push("scss") : null;
			this.tags.api ? tags.push("api") : null;
			this.tags.course ? tags.push("course") : null;
			this.tags.challenge ? tags.push("challenge") : null;
			this.tags.mongodb ? tags.push("mongodb") : null;
			this.tags.nodejs ? tags.push("nodejs") : null;
			this.tags.react ? tags.push("react") : null;
			this.tags.firebase ? tags.push("firebase") : null;

			this.project.tags = tags;
		},
	},
	created() {
		this.ProcessTags();
	},
};
</script>

<style></style>

<style scoped lang="scss">
@import "../../scss/_mixins.scss";
@import "../../scss/variables";

.container {
	padding: 2rem 0 0 0;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;

	@include respond(tab-land) {
		grid-template-columns: 1fr;
	}
}

h2 {
	font-size: 2.7rem;
	color: #ffffff;
	font-weight: 500;
	margin-bottom: 2rem;
}

.left-side {
	width: 100%;
	padding: 0 2rem 0 0;
}
.right-side {
	padding: 0 15rem;
	@include respond(laptop) {
		padding: 0 5rem 0 5rem;
	}
	@include respond(tab-land) {
		display: none;
	}
}
.checkboxes {
	display: flex;
}
.form-control {
	display: flex;
	flex-direction: column;

	&-check {
		margin-right: 1.5rem;
		margin-bottom: 2rem;

		label {
			color: #ffffff;
			font-size: 1.6rem;
			margin-right: 0.5rem;
		}
	}

	label {
		color: #ffffff;
		font-size: 2.3rem;
	}

	input {
		height: 100%;
		border-radius: 0.6rem;
		padding: 0.5rem 1rem;
		background-color: #212121;
		border: none;
		color: #ffffff;
		line-height: 2rem;
		font-size: 2rem;
		margin-right: 1rem;
		margin-bottom: 1.6rem;

		@include respond(tab-port) {
			width: 100%;
		}
	}
	textarea {
		height: 100%;
		border-radius: 0.6rem;
		padding: 0.5rem 1rem;
		background-color: #212121;
		border: none;
		color: #ffffff;
		line-height: 2rem;
		font-size: 2rem;
		margin-right: 1rem;
		margin-bottom: 1.6rem;
		resize: none;

		@include respond(tab-port) {
			width: 100%;
		}
	}
}

button {
	background-color: #7159e9;
	border-radius: 0.5rem;
	border: none;
	font-size: 1.8rem;
	color: $text-light;
	padding: 1rem 3rem;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: #ffffff;
		color: #212121;
		cursor: pointer;
	}

	i {
		margin-left: 1rem;
	}
}
</style>
